<template>
  <div v-if="homeItems" :class="animationIndexClasses">
    <HomeIndexHero
      :imageDesktop="homeItems.entityById.fieldImage.entity.uriRawField.list[0].url"
      :imageMobile="homeItems.entityById.fieldImageMobile.entity.uriRawField.list[0].url"
      :description="homeItems.entityById.fieldDescription"
      :description2="homeItems.entityById.fieldDescription2"
    />
    <div class="mx-auto index-search">
      <HomeIndexSearch
        :title="homeItems.entityById.fieldTitleCancer"
        :text="homeItems.entityById.fieldTextCancer"
        :image="homeItems.entityById.fieldImageCancer.entity.uriRawField.list[0].url"
        :imageAlt="homeItems.entityById.fieldImageCancer.alt"
        :listCancerItems="homeItems.entityById.fieldCancerLink"
        :listCancerItemsBottom="homeItems.entityById.fieldCancerLinkBottom"
      />
      <HomeIndexEdito
        :title="homeItems.entityById.fieldTitleEditorial"
        :cards="homeItems.entityById.fieldHomeEditorial"
        class="my-20"
      />
      <HomeIndexAction
        :title="homeItems.entityById.fieldTitleAction"
        :cards="homeItems.entityById.fieldHomeAction"
        class="my-20"
      />
      <HomeIndexHotContent
        v-if="homeItems.entityById.fieldHomeProject.length > 0"
        :projects="homeItems.entityById.fieldHomeProject"
        class="my-20"
      />
      <HomeIndexActuality
        :title="homeItems.entityById.fieldTitleNews"
        :news="homeItems.entityById.computedNewsHighlighted"
        class="my-20"
      />
      <HomeIndexLegacy
        :title="homeItems.entityById.fieldTitleLegacy"
        :introduction="homeItems.entityById.fieldIntroductionLegacy"
        :text="homeItems.entityById.fieldTextLegacy"
        :link="homeItems.entityById.fieldLinkLegacy"
        :image="homeItems.entityById.fieldImageLegacy"
        class="my-20"
      />
      <HomeIndexCooperation
        :subTitle="homeItems.entityById.fieldSubtitle"
        :title="homeItems.entityById.fieldTitleCooperation"
        :text="homeItems.entityById.fieldTextCooperation"
        :image="homeItems.entityById.fieldImageCooperation"
        :logos="homeItems.entityById.fieldLogoCooperation"
        :links="homeItems.entityById.fieldLink"
        class="my-20"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useSeo from "~/composables/seo";
import getHome from "~/composables/service/graphql/client/home/index";

import type { SeoTags } from "~/interfaces/seo";
import { getDefaultPageTags } from "~/utils/cacheTags";

const config = useRuntimeConfig();
const route = useRoute();
const langcode = config.public.langcode;
const { data: homeItems } = await getHome(route.name?.toString()!, langcode);
/*useRouteCache((helper) => {
  helper.setMaxAge(86400).setCacheable().addTags([`page:${homeItems.entityById?.id}`, "type:page"])
})*/

const { setMetaTags, getBackendMetatags, formatSeoTag } = useSeo();

const formattedMetatags = computed<SeoTags>(() => {
  const backendMetatags = getBackendMetatags(homeItems.entityById?.fieldMetatag);
  const title = formatSeoTag("title", backendMetatags?.title ?? homeItems.entityById?.fieldTitleAction);
  const description = formatSeoTag(
    "description",
    backendMetatags?.description ?? homeItems.entityById?.fieldDescription,
    backendMetatags?.title ?? homeItems.entityById?.fieldTitleAction
  );

  
  const canonicalUrl = backendMetatags?.canonical_url ?? `${config.public.frontendHost}${route.fullPath}`;

  return { title, description, canonical_url: canonicalUrl, ...backendMetatags};
});

setMetaTags(formattedMetatags.value);

// Réactivité pour la position de défilement
const scrollPosition = ref(0);

// Mise à jour de la position de défilement
const updateScroll = () => {
  scrollPosition.value = window.scrollY;
};

// Écouteur d'événements pour le défilement
onMounted(() => {
  window.addEventListener("scroll", updateScroll);
});

// Computed property pour les classes d'animation
const animationIndexClasses = computed(() => {
  return scrollPosition.value < 100 ? "isScrollUp" : "isScrollDown";
});
const newsTags = homeItems.entityById.computedNewsHighlighted.map((news)=>{return `page:${news.id}`});
const tags = [`page:${homeItems.entityById.id}`, ...getDefaultPageTags(), ...newsTags];
useCDNHeaders((helper) => {
  helper
    .addTags(tags)
    .public()
    .setNumeric('maxAge', 86400)
    .setNumeric('staleIfError', 43200)
})

useRouteCache((helper) => {
  helper.setMaxAge(86400).setCacheable().addTags(tags)
})

useJsonld(() => ({
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Institut Curie",
  url: config.public.frontendHost,
  logo: config.public.frontendHost + "/_nuxt/assets/img/menu/logo-color.svg",
  contactPoint: {
    contactType: "ContactPoint",
    telephone: "+33156245500"
  },
  address: [
    {
      "@type": "PostalAddress",
      "streetAddress": "26 rue d'Ulm",
      "addressLocality": "Paris",
      "postalCode": "75248",
      "addressCountry": "FR"
    }
  ]
}));
</script>
