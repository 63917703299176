import { HomeQuery } from "../../query/content/Home";
import type { HomeItems } from "~/interfaces/home";



export default async function getHome(id: string, langcode: string) {
  const TAGS : string[] = [];
  const nuxtApp = useNuxtApp();
  const { client } = useApolloClient();
  const key = `page--${id}`;
  const { data: home } = await useAsyncData(key, async () => {
    const { value, addToCache } = await useDataCache(key)
    if (value) {
      return value
    }
    const { data: response }  =  await client.query<HomeItems>({query: HomeQuery, variables: { id, langcode }});
    TAGS.push(`page:${id}`);
    if(response.entityById?.computedNewsHighlighted){
      for (const element of response.entityById?.computedNewsHighlighted) {
        TAGS.push(`page:${element.id}`);
      }
    }
    await addToCache(response, TAGS);
    return response;
  })
  return {data: home.value };
}