<template>
  <div class="container mx-auto">
    <Title2 :title="title"/>
    <div class="grid grid-cols-4 gap-4  lg:gap-6">
      <div
          v-for="(card, index) in cards"
          :key="index"
          class="edito-card group flex flex-col col-span-4 md:col-span-2 lg:col-span-1 lg:last:col-span-2 h-fit p-4 pb-6 bg-white border border-grisClair rounded-md last:border-none last:p-0 last:relative"
      >
        <div class="edito-img overflow-hidden rounded">
          <CustomImage
              v-if="index === cards.length - 1"
              :src="card.fieldImage.entity.uriRawField.list[0].url"
              :alt="card.fieldImage.alt"
              presets="index_edito_large"
              customClass="w-full transition-all duration-[0.6s] hover:scale-[1.2]"
          />
          <CustomImage
              v-else
              :src="card.fieldImage.entity.uriRawField.list[0].url"
              :alt="card.fieldImage.alt"
              presets="index_edito_small"
              customClass="w-full rounded transition-all duration-[0.6s] hover:scale-[1.2]"
          />
        </div>
        <h3 class="mt-4 mb-0">
          <UiCustomLink
              :path="card.fieldLink.uri.path"
              :class="[
            index === cards.length - 1
              ? 'absolute text-white bottom-2 lg:bottom-6 left-6 text-[28px] leading-[33px] lg:leading-[40px] lg:text-[34px] font-lora font-normal w-[90%] lg:w-3/4'
              : 'text-medium lg:text-h3Small font-normal font-lora leading-[26px] lg:leading-[24px] w-full',
            'mt-4',
          ]"
          >
            {{ card.fieldLink.title }}
            <nuxt-icon
                id="ArrowNoFill"
                name="arrowRightHome"
                class="ArrowRight block opacity-0 h-0 p-0 text-regular transition duration-150 ease-in-out group-hover:pt-4 group-hover:h-fit group-hover:opacity-100"

            />
          </UiCustomLink>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import Title2 from "../ui/Title2.vue";
import CustomImage from "~/components/ui/CustomImage.vue";

export default {
  name: "IndexEdito",
  components: {
    Title2,
    CustomImage,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    cards: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style>
.edito-card:last-child .ArrowRight {
  height: fit-content;
}

#ArrowNoFill svg  {
  fill:transparent !important;
}

#ArrowNoFill svg path  {
  fill:transparent !important;
}

.theme-dark {
  .edito-img {
    background-color: black;
    img {
      opacity: 0.8;
    }

  }
}
</style>
