<template>
  <UiCustomLink
      :path="linkUrl"
  >
    <div
        :class="cardClass"
        :style="`background-color: ${bgColor}; color: ${textColor}; ${generateBorder}`"
    >
      <div class="relative py-6 min-h-36">
        <CustomImage
            :src="icon1"
            presets="index_action_logo"
            customClass="h-24 block"
        />
        <CustomImage
            :src="icon2"
            presets="index_action_logo"
            customClass="absolute left-14 top-6 group-hover:translate-x-[-44px] duration-500 h-24 block"
        />
      </div>
      <p class="my-4 mt-6 text-lg font-normal leading-6 font-raleway">
        {{ text }}
      </p>
      <div class="font-lora font-normal text-[28px] md:text-[34px] leading-10 flex justify-between">
        {{ linkLabel }}
        <nuxt-icon
            name="arrowRightHomeAlt"
            id="ArrowNoFill"
            class="mt-1 mr-4 duration-500 group-hover:translate-x-5"
        />
      </div>
    </div>
  </UiCustomLink>
</template>

<script>
import CustomImage from "~/components/ui/CustomImage.vue";

export default {
  name: "ActionCard",
  components: {
    CustomImage,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    linkLabel: {
      type: String,
      default: "",
    },
    linkUrl: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "",
    },
    icon1: {
      type: String,
      default: "",
    },
    icon2: {
      type: String,
      default: "",
    },
  },
  computed: {
    cardClass() {
      if(this.bgColor === '#fff'){
        return `px-4 pb-[20px] pt-12 rounded border border-grisClair flex flex-col justify-end group cursor-pointer`;
      }
      return `px-4 pb-[20px] pt-12 rounded border flex flex-col justify-end group cursor-pointer`;
    },
    generateBorder() {
      if (this.bgColor !== '#fff') {
        return 'border-color:' + this.bgColor;
      }
    }
  },
};
</script>
<style>
#ArrowNoFill svg {
  fill: transparent !important;
}

#ArrowNoFill svg path {
  fill: transparent !important;
}
</style>