<template>
  <div v-if="news?.length" class="container mx-auto">
    <div class="flex justify-between my-6">
      <h2
        class="text-h2Small md:text-h2 font-normal leading-[35.2px] md:leading-[44px] font-raleway"
      >
        {{ title }}
      </h2>
      <div class="self-end hidden md:flex">
        <UiCustomLink
           v-if="backLink"
          :path="backLink"
          class="flex items-center text-lg font-bold underline font-raleway hover:text-orangeCurie"
        >
          {{  $t("actuality.discoverAll") }}
          <nuxt-icon name="arrowRight" filled class="ml-2" />
        </UiCustomLink>
      </div>
    </div>
    <div class="flex flex-wrap lg:flex-nowrap gap-10">
      <HomeCardsActualityCard
        v-for="newsHighlighted in news"
        :tag="newsHighlighted.fieldSubtitle"
        :image="newsHighlighted.fieldImage?.entity.uriRawField.list[0].url"
        :title="newsHighlighted.fieldTitle"
        :date="formatDate(newsHighlighted.fieldDate.value)"
        :path="newsHighlighted.pathRawField.list[0].alias"
      />
    </div>
    <div class="self-end mt-6 md:hidden">
      <UiCustomLink
        v-if="backLink"
        :path="backLink"
        class="flex items-center text-regular font-bold underline font-raleway hover:text-orangeCurie"
      >
        {{  $t("actuality.discoverAll") }}
        <nuxt-icon name="arrowRight" filled class="ml-2" />
      </UiCustomLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import Title2 from "../ui/Title2.vue";
import ActualityCard from "./cards/ActualityCard.vue";
import { useBackLink } from "~/composables/backlink";

const { getBackLink } = useBackLink();
const backLink = getBackLink("news_list");

  const props = defineProps({
    title: {
      type: String,
      required: true,
    },
    news: {
      type: Array
    }
  });
</script>