<template>
  <h2
    class="text-[32px] lg:text-h2 font-normal leading-[35.2px] md:leading-[52.8px] font-raleway my-4"
  >
    {{ title }}
  </h2>
</template>

<script>
export default {
  name: "Title2",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
