<template>
  <div class="container mx-auto">
    <div class="">
      <div
        class="bg-white border border-grisClair rounded-md mb-12 lg:max-w-[1090px] mx-auto px-4 py-6 lg:p-8 relative z-10"
      >
        <div class="flex flex-col lg:flex-row gap-6 lg:gap-[3.75rem]">
          <div class="flex flex-col flex-1 w-full lg:w-1/2">
            <h2
              class="md:w-3/4 text-h3Medium lg:text-h2Small leading-[40px] font-normal font-lora"
            >
              {{ title }}
            </h2>
            <div
              class="mt-4 mb-11 lg:mb-6 leading-[25px] text-regular font-normal font-raleway"
              v-html="text"
            />
            <DropdownCancer :listItems="listCancerItems" />
            <ul v-if="listCancerItemsBottom.length > 0" class="mt-10 pt-5 border-t border-grisClair">
              <li v-for="link in listCancerItemsBottom">
                <UiCustomLink
                  :path="link.uri.path"
                  class="flex items-center text-regular font-bold underline font-raleway hover:text-orangeCurie">
                  {{  link.title }}
                  <nuxt-icon name="arrowRight" filled class="ml-2" />
                </UiCustomLink>
              </li>
            </ul>
          </div>
          <div class="w-full lg:w-1/2">
            <CustomImage
              :src="image"
              presets="index_search"
              :alt="imageAlt"
              customClass="w-full rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../ui/Button.vue";
import CustomImage from "../ui/CustomImage.vue";
import DropdownCancer from "../ui/DropdownCancer.vue";

export default {
  name: "IndexSearch",
  components: {
    Button,
    CustomImage,
    DropdownCancer,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    imageAlt: {
      type: String,
      required: true,
    },
    listCancerItems: {
      type: Array,
      required: true,
    },
    listCancerItemsBottom: {
      type: Array,
      required: true,
    }
  },
};
</script>
