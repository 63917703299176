<template>
  <div class="container mx-auto">
    <Title2 :title="title" />
    <div class="grid grid-col-1 md:grid-cols-2 gap-6 lg:grid-cols-3">
      <ActionCard
        v-for="card in cards"
        :text="card.fieldTitle"
        :linkLabel="card.fieldLink.title"
        :linkUrl="card.fieldLink.uri.path"
        :icon1="card.fieldImages[0].entity.uriRawField.list[0].url"
        :icon2="card.fieldImages[1].entity.uriRawField.list[0].url"
        :bgColor="card.fieldBackgroundColor"
        :textColor="card.fieldTextColor"
      />
    </div>
  </div>
</template>

<script>
import Title2 from "../ui/Title2.vue";
import ActionCard from "./cards/ActionCard.vue";
export default {
  name: "IndexAction",
  components: {
    Title2,
    ActionCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    cards: {
      type: Array,
      required: true,
    },
  },
};
</script>
